<template>
  <div @click="closeNav">
    <v-app id="inspire">
      <v-navigation-drawer
        v-model="drawer"
        enable-route-watcher
        app
        enable-resize-watcher
      >
        <NavigationList />
      </v-navigation-drawer>

      <v-app-bar app color="primary" dark>
        <v-row fluid>
          <v-col lg="10">
            <v-app-bar-nav-icon
              class="float-left"
              @click.stop="drawer = !drawer"
            />
            <router-link to="/customer">
              <v-img
                v-if="$route.meta.provider != 'solution'"
                class="mx-2 mt-1 float-left"
                src="@/assets/img/gs1_logo.png"
                max-height="40"
                max-width="75"
              ></v-img>
              <v-img
                v-else
                class="mx-2 mt-1 float-left"
                src="@/assets/img/sol2.png"
                max-height="40"
                max-width="45"
              ></v-img>
            </router-link>
          </v-col>
          <v-col lg="2">
            <LanguageChange class="float-right" />
          </v-col>
        </v-row>
      </v-app-bar>

      <router-view class="pl-5 pr-5" :userData="userData"></router-view>

      <v-footer color="primary" app>
        <span class="white--text">&copy; {{currentYear}} GS1 Georgia v1.23</span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
import Auth from "@/helpers/authentication";
import NavigationList from "@/components/customer/Navigation";
import LanguageChange from "@/components/shared/Language";
export default {
  components: {
    NavigationList,
    LanguageChange,
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  beforeCreate() {
    this.userData = Auth.isAuthenticated();
  },
};
</script>
