<template>
  <v-list dense>
    <v-list-item link to="/customer" exact>
      <v-list-item-action>
        <v-icon color="primary">mdi-briefcase</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.tables.company")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link to="/customer/products" disabled>
      <v-list-item-action>
        <v-icon color="primary">mdi-cart</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.products")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="'/customer/invoices/membership/' + company_id"  disabled>
      <v-list-item-action>
        <v-icon color="primary">mdi-file</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.gs1_invoices")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link :to="'/customer/invoices/barcode/' + company_id"  disabled>
      <v-list-item-action>
        <v-icon color="primary">mdi-file</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.gs1_solutions_invoices")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item link @click="logout">
      <v-list-item-action>
        <v-icon color="primary">mdi-logout</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="subtitle-2">{{
          $vuetify.lang.t("$vuetify.navigations.logout")
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import Auth from "@/helpers/authentication";
export default {
  data: function() {
    return {
      perm: Auth.isAuthenticated()["permission"],
      company_id: Auth.isAuthenticated()["company_id"],
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      return (window.location.href = process.env.VUE_APP_SITE_BASE_URL);
    },
  },
};
</script>
