<template>
  <ul class="localeSelector float-right mt-3">
    <li @click="changeLang('ka')"><v-img src='@/assets/img/ka.png' style="width:28px"/></li>
    <li @click="changeLang('en')"><v-img src='@/assets/img/en.png' style="width:28px"/></li>
  </ul>
</template>

<script>
import i18n, { languagesArray, changeLanguage } from "@/plugins/i18n/index.js";
export default {
  name: "LanguageChange",
  data() {
    return {
      langs: languagesArray,
      lang: i18n.locale
    };
  },
  methods: {
    changeLang(lang) {
      changeLanguage(lang);
      if(this.$route.fullPath === '/manager') {
        window.location.href = this.$route.fullPath;
      }
    }
  }
};
</script>

<style scoped>
.localeSelector {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}
.localeSelector li {
  display: inline-block;
  padding-left:5px;
  cursor: pointer;
}
</style>